import Loader from '@sportnet/ui/Loader';
import TheLayout from '@sportnet/ui/TheLayout';
import { INavigationItems } from '@sportnet/ui/TheLayout/Navigation';
import { IApp, IAppSpace } from '@sportnet/ui/TheLayout/types';
import MediaManagerConnector from '@sportnet/media-manager-connector';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import { NavLink } from 'react-router-dom';
import {
  activeAppspaceNameSelector,
  activeAppspaceSelector,
  applicationInfoSelector,
  appspacesSelector,
  appsSelector,
  authUserSelector,
  tokenSelector,
} from '../../pages/App/selectors';
import Editor from '../../pages/Editor';
import { settingsSelector } from '../../pages/Editor/selectors';
import Settings from '../../pages/Settings';
import { RootState } from '../../rootReducer';
import styled from '../../theme/styled-components';
import { __ } from '../../utilities';
import { useAuth } from '@sportnet/auth-react';
import CoreApi from '../../CoreApi';
import config from '../../config';
import { getApiError } from 'utilities/getApiError';
import { AuthUser } from '@sportnet/auth/validateIdToken';
import { setActiveAppspace } from 'pages/App/actions';
import getCmsUrl from 'utilities/getCmsUrl';

interface AppState {
  isLoading: boolean;
  // objekt appspace s ktorym pracujem
  activeAppSpace?: IAppSpace;
  // vsetky appspaces ku ktorym ma uzivatel pristup
  appSpaces?: IAppSpace[];
  // info o aplikacii s ktorou pracujem
  activeApp?: IApp;
  // appky, ku ktorym ma uzivatel pristup
  apps?: IApp[];
  // priznak, ci ma uzivatel pre danu ppo grant prava
  grant?: boolean;
  //
  error?: string;
}

interface Action {
  type: string;
  payload?: Partial<AppState>;
}

const INITIAL_APP_STATE: AppState = {
  isLoading: false,
};

const reducer = (state: AppState, action: Action) => {
  switch (action.type) {
    case 'START_AUTH': {
      return {
        ...INITIAL_APP_STATE,
        isLoading: true,
      };
    }
    case 'DONE': {
      if (action.payload) {
        const { apps, appSpaces, activeAppSpace, activeApp } = action.payload;
        return {
          ...state,
          apps,
          appSpaces,
          activeApp,
          isLoading: false,
          activeAppSpace,
        };
      }
      return state;
    }
    case 'ERROR': {
      return {
        ...INITIAL_APP_STATE,
        isLoading: false,
      };
    }
    default: {
      return state;
    }
  }
};

const LoaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface OwnProps {
  topFixed?: JSX.Element;
  bottomFixed?: JSX.Element;
}

const mapStateToProps = (state: RootState) => ({
  appspace: activeAppspaceSelector(state),
  appspaces: appspacesSelector(state),
  applicationInfo: applicationInfoSelector(state),
  apps: appsSelector(state),
  organizationName: activeAppspaceNameSelector(state),
  authUser: authUserSelector(state),
  token: tokenSelector(state),
  settings: settingsSelector(state),
});

const mapDispatchToProps = {
  setActiveAppspace,
};

type Props = OwnProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

const getDisplayName = (authUser?: AuthUser) => {
  return authUser?.appSpace?.displayName ?? '';
};

const getRole = (appId: string, authUser?: AuthUser) => {
  return (authUser?.appSpace?.roles ?? {})[appId];
};

const getGrant = (authUser?: AuthUser) => {
  return authUser?.appSpace?.grant ?? false;
};

const Administration: React.FC<Props> = ({
  bottomFixed,
  topFixed,
  setActiveAppspace,
  settings,
}) => {
  const [state, dispatch] = React.useReducer(reducer, INITIAL_APP_STATE);

  const {
    authUser,
    app: appId,
    ppo: appSpace,
    logout,
    accessToken,
  } = useAuth();

  const [isMediaManagerOpened, setIsMediaManagerOpened] = React.useState(false);
  const [notificationCount, setNotificationCount] = React.useState(-1);

  const timerRef = React.useRef<number>();

  const displayName = getDisplayName(authUser);
  const role = getRole(appId, authUser);
  const userPictureSrc = authUser?.photoIsPublic
    ? authUser?.photoUrl ?? ''
    : '';
  const grant = getGrant(authUser);

  const cmsUrl =
    appSpace && settings
      ? getCmsUrl(appId, appSpace, config.DEFAULT_CONTENT_DIVIDER, settings)
      : '#';

  React.useEffect(() => {
    if (!appSpace) {
      return;
    }
    (async () => {
      try {
        dispatch({ type: 'START_AUTH' });
        const response = await CoreApi.meAppSpaces({ expandApp: true });
        const apps = (response?.apps || []).map(a => {
          const appSpaceIds = (a.appspaces || []).map(as => as.app_space);
          return { ...a.app, appSpaceIds };
        }) as IApp[];

        const appObj = (response.apps || []).find(a => a.app_id === appId);

        const appSpaces =
          appObj && Array.isArray(appObj.appspaces)
            ? appObj.appspaces.map(
                appAppSpace => appAppSpace.org_profile as IAppSpace,
              )
            : [];

        const activeApp = (await CoreApi.getPublicApp(appId)) as IApp;

        const activeAppSpace = (await CoreApi.organizationPPOProfile(
          appSpace,
        )) as IAppSpace;

        setActiveAppspace(appSpace);

        dispatch({
          type: 'DONE',
          payload: {
            activeAppSpace,
            appSpaces,
            activeApp,
            apps,
          },
        });
      } catch (e) {
        const errorObj = getApiError(e);
        const error = `${errorObj.details.statusCode}: ${errorObj.details.name} - ${errorObj.details.description}`;
        dispatch({ type: 'error', payload: { error } });
      }
    })();
  }, [appSpace, appId, dispatch]);

  // Loadovanie externych sprav
  React.useEffect(() => {
    if (!accessToken) {
      return;
    }

    const fetchNotificationCount = async () => {
      try {
        const { count } = await CoreApi.meUnreadMessagesCount();
        setNotificationCount(count ?? 0);
        if (timerRef.current) {
          window.clearInterval(timerRef.current);
        }
        timerRef.current = window.setTimeout(fetchNotificationCount, 300000);
      } catch (e) {
        setNotificationCount(-1);
      }
    };

    fetchNotificationCount();

    return () => {
      if (timerRef.current) {
        window.clearInterval(timerRef.current);
      }
    };
  }, [accessToken]);

  const getNavigationItemUrl = (itemUrl: string) => {
    if (appSpace) {
      return `/admin/${appSpace}${itemUrl}`;
    }
    return itemUrl;
  };

  const navigationItems: INavigationItems = [
    {
      icon: 'monitor',
      label: __('Vzhľad stránky'),
      url: getNavigationItemUrl('/editor'),
      subitems: [
        {
          label: __('Šablóny'),
          url: getNavigationItemUrl('/editor/layouts'),
        },
        {
          label: __('Priradenie šablón sekciám'),
          url: getNavigationItemUrl('/editor/section-layouts'),
        },
      ],
    },
    {
      icon: 'edit-document',
      label: __('Správa obsahu'),
      url: cmsUrl,
    },
    {
      icon: 'folder',
      label: __('Media Manager'),
      onClick: () => {
        setIsMediaManagerOpened(true);
      },
    },
    {
      icon: 'settings',
      label: __('Nastavenia'),
      subitems: [
        {
          label: __('Doména'),
          url: getNavigationItemUrl('/domain'),
        },
        {
          label: __('Hlavná stránka'),
          url: getNavigationItemUrl('/homepage'),
        },
        {
          label: __('Analytika'),
          url: getNavigationItemUrl('/analytics'),
        },
        {
          label: __('Kontaktný formulár'),
          url: getNavigationItemUrl('/contact-form'),
        },
        {
          label: __('Sociálne siete'),
          url: getNavigationItemUrl('/social'),
        },
      ],
    },
  ];

  const handleMediaManagerClose = React.useCallback(() => {
    setIsMediaManagerOpened(false);
  }, []);

  const { activeAppSpace, activeApp, appSpaces, apps } = state;

  return (
    <TheLayout
      accessToken={accessToken}
      navigation={navigationItems}
      userName={displayName}
      notificationCount={notificationCount}
      userPictureSrc={userPictureSrc}
      top={topFixed}
      bottom={bottomFixed}
      onLogout={() => {
        logout();
      }}
      app={activeApp}
      appSpace={activeAppSpace}
      appSpaces={appSpaces}
      apps={apps}
      renderNavigationLink={({ item, linkProps }) => {
        if (item.url?.startsWith('http')) {
          return (
            <a
              className={linkProps.className}
              href={item.url}
              target="_blank"
              rel="noreferrer noopener"
            >
              {linkProps.children}
            </a>
          );
        }
        return (
          <NavLink exact to={item.url || ''} {...linkProps}>
            {linkProps.children}
          </NavLink>
        );
      }}
      userSubName={role}
      grant={grant}
    >
      {state.isLoading ? (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      ) : (
        <>
          {appSpace && accessToken && (
            <>
              <Switch>
                <Redirect
                  exact
                  from="/admin/:appspace"
                  to={getNavigationItemUrl('/editor')}
                />
                <Route path="/admin/:appspace/editor" component={Editor} />
                <Route path="/admin/:appspace" component={Settings} />
              </Switch>
              <MediaManagerConnector
                opened={isMediaManagerOpened}
                appId={appId}
                appSpace={appSpace}
                accessToken={accessToken}
                onClose={handleMediaManagerClose}
                onError={(e: any) => {
                  handleMediaManagerClose();
                  throw e;
                }}
                onUnauthorized={() => {
                  handleMediaManagerClose();
                  throw Error('Media manager unauthorized');
                }}
              />
            </>
          )}
        </>
      )}
    </TheLayout>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Administration);
